import { MarketplaceName } from 'src/graphql/generated';

// TODO NEW MARKETPLACE - might not be needed since key must be from MarketplaceName
// to transform 2chart - 3char see https://www.nbs.sk/sk/statisticke-udaje/kurzovy-listok/denny-kurzovy-listok-ecb
export const MARKETPLACE_CURRENCIES: { [key in MarketplaceName]: string } = {
    amazon_de: 'EUR',
    amazon_uk: 'GBP',
    amazon_nl: 'EUR',
    amazon_it: 'EUR',
    amazon_fr: 'EUR',
    amazon_es: 'EUR',
    amazon_mx: 'MXN',
    amazon_us: 'USD',
    amazon_ca: 'CAD',
    amazon_jp: 'JPY',
    amazon_au: 'AUD',
    amazon_sg: 'SGD',
    amazon_se: 'SEK',
    amazon_tr: 'TRY',
    amazon_pl: 'PLN',
    amazon_be: 'EUR',
    amazon_ie: 'EUR',
    amazon_br: 'BRL',
    mall_cz: 'CZK',
    mall_sk: 'EUR',
    mall_pl: 'PLN',
    mall_hu: 'HUF',
    mall_si: 'EUR',
    alza_cz: 'CZK',
    emag_pl: 'PLN',
    emag_hu: 'HUF',
    emag_ro: 'RON',
    emag_bg: 'BGN',
    real_de: 'EUR',
    kaufland_de: 'EUR',
    kaufland_cz: 'CZK',
    kaufland_sk: 'EUR',
    kaufland_at: 'EUR',
    kaufland_pl: 'PLN',
    glami_cz: 'CZK',
    cdiscount_fr: 'EUR',
    ebay_us: 'USD',
    ebay_uk: 'GBP',
    ebay_fr: 'EUR',
    ebay_de: 'EUR',
    ebay_pl: 'PLN',
    ebay_at: 'EUR',
    ebay_cz: 'CZK',
    allegro_cz: 'CZK',
    allegro_pl: 'PLN',
    allegro_sk: 'EUR',
    baskedo_bg: 'BGN',
    baskedo_hu: 'HUF',
    bol_nl: 'EUR',
    expando_shop_ro: 'RON',
    limetka_cz: 'CZK',
    limetka_gr: 'EUR',
    limetka_sk: 'EUR',
};
export default MARKETPLACE_CURRENCIES;

export function getCurrency(marketplace: string | MarketplaceName) {
    return MARKETPLACE_CURRENCIES[marketplace];
}

export function getCurrencyChar(currencyCode) {
    switch (currencyCode) {
        case 'EUR':
            return '€';
        case 'GBP':
            return '£';
        case 'USD':
        case 'CAD':
            return '$';
        case 'JPY':
            return '¥';
        case 'AUD':
            return 'A$';
        case 'CZK':
            return 'Kč';
        case 'PLN':
            return 'zł';
        default:
            return currencyCode;
    }
}
